//Libraries
@import 'leaflet/dist/leaflet.css';
@import 'ol/ol.css';
@import 'jquery-ui/themes/base/all.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

//Local library (reason for this is, that some good guy made changes directrly in library without even documenting it)
@import 'libraries/bootstrap/bootstrap';

//Base
@import 'base/variables';
@import 'base/layout-constants';
@import 'base/typography';
@import 'base/reset';
@import 'base/flags';

//Functions
@import 'functions/rem';

//Helpers
@import 'helpers/helpers';

//General components
@import 'components/buttons';
@import 'components/section';
@import 'components/wrapper';
@import 'components/forms';
@import 'components/divider';
@import 'components/slider';
@import 'components/table';
@import 'components/submenu';
@import 'components/hamburger';
@import 'components/footer';
@import 'components/search';
@import 'components/opacity-slider';
@import 'components/datepicker';
@import 'components/dropdown';
@import 'components/checkboxes';
@import 'components/tooltip';
@import 'components/spinner';
@import 'components/alert';
@import 'components/dialog';
@import 'components/frontend-flash';

//Specific components for landing
@import 'components/landing/body';
@import 'components/landing/login';
@import 'components/landing/video';
@import 'components/landing/menu';
@import 'components/landing/header';
@import 'components/landing/topnav';
@import 'components/landing/captcha';

//Specific components for Portal
@import 'components/portal/body';
@import 'components/portal/content';
@import 'components/portal/tables';
@import 'components/portal/menu';
@import 'components/portal/header';
@import 'components/portal/topnav';

//Sidenav
@import 'components/sidenav/topbar';
@import 'components/sidenav/submenu';
@import 'components/sidenav/sidenav';
@import 'components/sidenav/menu';
@import 'components/sidenav/block';
@import 'components/sidenav/content';
@import 'components/sidenav/form';
@import 'components/sidenav/legend-table';
@import 'components/sidenav/histogram';
@import 'components/sidenav/calendar';
@import 'components/sidenav/checklist-dropdown';
@import 'components/sidenav/user-selection';
//Map
@import 'components/map/map';
@import 'components/map/legend';
@import 'components/map/controls';
@import 'components/map/edit';

//Documents
@import 'components/document/document';

//Contact
@import 'components/contact/contact';
@import 'components/contact/map';

//Support
@import 'components/support/support';
@import 'components/mobile';

//Uploads
@import 'components/uploads/uploads';
@import 'components/uploads/content';

//Yield-potential
@import 'components/yield-potential/yield-potential';

//Measurement tools
@import 'components/measurement/tooltip';

//Integrations
@import 'components/integrations/integrations';
