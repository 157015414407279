/* Tables in content part */
/* General styles */

.c-table {
    &__form {
        display: inline-flex;
        justify-content: center;
    }
}

.culti-content-tabular {
    font-weight: 600;
    font-style: italic;
}

.culti-content-tabular a {
    color: var(--culti-black);
}

.culti-content-tabular a:hover {
    color: var(--culti-green);
}

.culti-content-tabular table {
    margin: 0 auto 1em auto;
    border-radius: 13px;
    overflow: hidden;
    width: 100%;
}

/* Cells */

.culti-content-tabular table td,
.culti-content-tabular table th {
    padding: 1em;
    font-weight: 400;
    text-align: center;
}

.culti-content-tabular ul.pagination li::after {
    content: '';
    display: inline-block;
    width: 3px;
}

.culti-content-tabular table td {
    font-weight: 600;
    padding: 1.5em 1em;
}

.culti-content-tabular table td img {
    height: 1.2em;
}

.culti-content-tabular table td img.image-count {
    margin-bottom: .2em;
    margin-right: .7em;
}

.culti-content-tabular table td.actions a {
    border: none;
    margin-right: 10%;
}

.culti-content-tabular table td.actions a:last-child {
    margin-right: 0;
}

/* Heading */

.culti-content-tabular table thead {
    background-color: var(--culti-dark-green);
    color: var(--culti-white);
    font-weight: 400;
    font-style: italic;
}

.culti-content-tabular table thead a {
    color: var(--culti-white);
}

.culti-content-tabular table thead a:hover {
    color: var(--culti-white);
}

/* Body */

.culti-content-tabular table tbody tr:nth-child(odd) {
    background-color: var(--culti-white);
}

.culti-content-tabular table tbody tr:nth-child(even) {
    background-color: var(--culti-light-grey);
}
