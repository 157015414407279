.c-dropdown {
    position: relative;
    display: flex;
    justify-content: center;

    &__menu {
        display: none;
        position: absolute;
        background-color: white;
        border-radius: 3px;
        bottom: 0;
        z-index: 1000;
        box-shadow: rgba(26, 26, 26, 0.2) 0px 0px 12px;
        transform: translateY(calc(100% - 0.8rem));
        min-width: 7rem;


        &.show {
            display: flex;
            flex-direction: column;
        }
    }

    &__item {
        padding: 0.5rem 1rem;
        color: var(--culti-black);
        font-size: 0.625rem;
        outline: none;
        border: none;
        background: transparent;
        text-align: left;

        &:hover {
            background-color: var(--culti-light-grey);
            color: var(--culti-black);
        }
    }
}