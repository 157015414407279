.c-sidenav-content {
    font-size: 0.625rem;
    padding: 0.625rem 1.25rem;
    line-height: 1.44;
    background-color: var(--culti-white);
    width: 100%;

    p {
        font-size: 0.625rem;
        font-style: italic;
        font-weight: 400;
        color: var(--culti-black);
        margin-bottom: 1rem;
    }

    h4 {
        font-size: 0.625rem;
        font-style: italic;
        font-weight: 600;
        color: var(--culti-black);
    }

    table {
        margin-bottom: 0;
    }

    &__coming-soon {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }

    &__no-available {
        p {
            margin-bottom: 0;
        }
    }

    &__icon {
        width: 1.3rem;
        height: auto;
        margin-right: 0.5rem;
    }

    &__edit-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin-right: 0.5rem;
    }

    &__edit-buttons {
        display: flex;
    }

    &__save-button {
        padding: 0.375rem 0.75rem;
        margin: 0.75rem auto 0.75rem auto;
    }

    &__info {
        margin-top: 2rem;
        display: flex;
        align-items: center;
    }

    &__info-icon {
        margin-right: 0.5rem;
        width: 1rem;
        height: auto;
    }

    &__info-text {
        margin-bottom: 0rem !important;
    }
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content.culti-content {
    padding-left: var(--sidenav-width);
    width: calc(100% - var(--sidenav-width));
}
