.wrapper {
    display: table;
    width: auto;
    position: relative;
    width: 100%;
}

.playpause {
    background-image: url(../img/button-play.svg);
    background-repeat: no-repeat;
    background-size: 7%;
    background-position: center;
    background-color: rgba(235, 248, 242, .7);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    padding-top: 2em;
}
