.tutorials-mobile {
    max-width: 90%;
}

.font-size-16 {
    font-size: 16px !important;
    font-weight: bold;
}

.container-no-mobile {
    padding-bottom: 0;
    margin: 1;
}

#layoutNoSidenav .container {
    padding-bottom: 3em;
    margin: 3em auto;
}

.culti-support-container {
    background-image: url('../img/Cultiwise_Portal_POLYGON_2.svg');
    background-position-y: bottom;
    background-position-x: 10%;
    background-repeat: no-repeat;
    background-size: 14em;
}

.culti-support-container h3 {
    font-size: 20px;
    display: block;
    margin-bottom: .7em;
    margin-top: 0;
}

.culti-support-container strong {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    font-style: normal;
}

.culti-support-container .tutorials {
    box-shadow: 0.5px 8px 15px 0 rgba(51, 51, 51, 0.15);
    background-color: var(--culti-white);
    border-radius: 20px;
    margin: 3em auto 8em auto;
    width: max-content;
    max-width: 100%;
    padding: 2em;
}

.culti-support-container .tutorials img {
    height: 8em;
}

.culti-support-container .tutorials div {
    padding: 1em 1.5em;
}

.culti-support-container .tutorials div:first-child {
    text-align: center;
}

.culti-support-container .contact {
    margin: 0 auto;
}

.culti-support-container .contact img {
    height: 5em;
}

.culti-support-container .phone {
    padding-top: 1em;
}

.culti-support-container .email {
    padding-top: 1em;
    font-style: italic;
    font-size: 14px;
}


.culti-support-container .email p {
    margin-top: 2em;
}


.c-support {

    &__polygon {
        &::after {
            content: url(../img/Cultiwise_Portal_POLYGON_1.svg);
            transform: translate(60%, -10%);
            top: 0;
            right: 0;
        }
    }

}
