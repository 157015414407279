.c-sidenav-topbar {
    background-color: var(--culti-dark-green);
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0.5px 3.6px 5.6px 0 rgba(51, 51, 51, 0.15);
    padding: 1em;
    z-index: 25;
    width: var(--sidenav-width);
    color: var(--culti-white);

    &__title {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--culti-white);
        text-transform: uppercase;
        display: inline;
    }

    &__name {
        font-size: 1.125rem;
        font-weight: 500;
        font-style: italic;
        color: var(--culti-white);;
        margin-bottom: 0.2rem;
        text-transform: capitalize;
        overflow: hidden;
        word-break: break-word;
    }

    &__position {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__position-title {
        font-size: 0.875rem;
        font-weight: 500;
        font-style: italic;
        color: var(--culti-white);
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__link {
        font-size: 0.75rem;
        display: flex;
        align-items: center;

        &:hover, &:active {
            color: white;
        }
    }

    &__arrow {
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.2rem;
    }

    &__table {
        width: 90%;
        font-size: 0.75rem;
        font-style: italic;
        color: var(--culti-white);
        border-top: 1px solid rgba(255, 255, 255, .3);
        margin: .5em 5% 0 5%;
    }

    &__icon {
        height: 2em;
        margin-right: 1em;
        margin-bottom: 6px;
    }

    &__pin {
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.2rem;
    }
}
