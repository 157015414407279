.c-integrations {
    &__btns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

    &__btn {
        width: 20rem;
        height: 4rem;
        margin: 10px;
        padding: 0.4rem 1rem;
        font-size: 1.1em;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        border: none;
        border-radius: 0.4em;
        transition: all 0.4s;
        color: var(--culti-white);

        &.hidden {
            display: none;
        }

        &:hover, &:focus {
            color: var(--culti-white);
            outline: none;
            cursor: pointer;
            background-color: var(--culti-black);
        }

        &__green {
            background-color: var(--culti-green);
        }

        &__red {
            background-color: var(--culti-red);
        }
    }

    &__text-container {
        text-align: center;
        margin: 2rem auto;
    }
}
