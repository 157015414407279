.c-portal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 2rem;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 100;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);
    height: 4.375rem;
    justify-content: space-between;
    left: 0;
    top: 0;
    z-index: 10000;


    @media #{$screen-1200} {
        height: 4.375rem;
        justify-content: unset;
    }

    &__brand {
        margin-right: 0rem;

        @media #{$screen-1200} {
            margin-right: 2rem;
        }

        @media #{$screen-1400} {
            margin-right: 3.125rem;
        }
    }

    &__logo {
        width: 8rem;

        @media #{$screen-1200} {
            width: 9rem;
        }

        @media #{$screen-1400} {
            width: 10.5rem;
        }
    }
}
