.c-sidenav-checklist-dropdown {

  position: relative;

  &.visible &__dropdown {
    display: block;
  }

  &.visible &__items {
    max-height: 20em;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2px;
  }

  &__anchor.focused {
    border-bottom: 2px solid var(--culti-hover);
  }

  &__anchor {
    cursor: pointer;
    padding-right: 20px;
    background: url(../img/icon-arrow-down.svg) no-repeat right;
    background-size: 1em;
    text-overflow: ellipsis;
  }

  &__dropdown {
    display: none;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding-top: 1px;
    position: absolute;
    background-color: var(--culti-white);
    z-index: 2;
  }

  &__label {
    font-size: 1.1em;
  }

  &__input {
    width: fit-content;
  }

}
