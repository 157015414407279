.c-map {
    position: fixed;
    left: 0;
    top: 4.375rem;
    width: 100%;
    height: calc(100% - 4.375rem);

    &__leaflet-popup {
        font-weight: 600;
        font-family: $default-font;
    }

    @media #{$screen-992} {
        left: 15.5rem;
        width: calc(100% - 15.5rem);
    }
}

/* TODO - find better way to edit zoom buttons */
/* Leaflet and openlayers specific styles */
#layoutSidenav_content #map {

    .ol-zoom {
        top: 2rem;
        right: 2rem;
        left: unset;
    }

    .leaflet-top {
        top: 2rem;
    }

    .leaflet-right {
        right: 2rem;
    }

    .leaflet-bar,
    .ol-zoom {
        border: none;
        border-radius: 2em;
        height: 2.25rem;
        background: none;
        margin: 0;
    }

    .leaflet-bar a,
    .ol-control button {
        display: inline-block;
        border: none;
        border-radius: 0;
        color: var(--culti-green);
        background-color: var(--culti-white);
        font-size: 2em;
        width: 50px;
        height: 35px;
        line-height: 35px;
        margin: 0;
        font-weight: 500;
        font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }

    .leaflet-bar a:hover,
    .ol-control button:hover {
        background-color: var(--culti-light-grey);
    }

    .leaflet-bar a:first-child,
    .ol-control .ol-zoom-in {
        border-radius: .625rem 0 0 .625rem;
        border-right: 1px solid var(--culti-light-grey);
    }

    .leaflet-bar a:last-child,
    .ol-control .ol-zoom-out {
        border-radius: 0 .625rem .625rem 0;
    }

    .ol-attribution {
        display: none;
    }
}
