.c-sidenav-submenu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-left: 0;
    background-color: var(--culti-light-grey);
    border-left: 2px solid var(--culti-green);

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    &__item {
        flex-direction: column;

        &:hover {
            .c-sidenav-submenu__edit {
                &:not(.hidden) {
                    display: block;
                }
            }

            .c-sidenav-submenu__delete {
                &:not(.hidden) {
                    display: block;
                }
            }

            .c-sidenav-submenu__bars {
                &:not(.hidden) {
                    display: block;
                }
            }
        }

        &.disabled {
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__item-inner {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 0 1rem;
        border-left: 2px solid transparent;

        &:hover {
            cursor: pointer;
            text-decoration: none;
            color: var(--culti-black);
            background-color: var(--culti-dark-grey);
        }

        &--nohover {
            &:hover {
                cursor: default;
                text-decoration: none;
                color: var(--culti-black);
                background-color: var(--culti-light-grey);
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }

    &__button-wrapper {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__button {
        display: flex;
        justify-content: center;
        padding: 0.3125rem 1.5rem;
    }

    &__button-logo {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
    }

    &__editable {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__link-wrapper {
        display: flex;
        align-items: center;
    }

    &__checkbox {
        margin-right: 0.5rem;
    }

    &__link {
        font-size: 0.8rem;
        font-weight: bold;
        color: var(--culti-black);
        width: 100%;
        padding: 0.875rem 0rem;
        margin: 0 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
            color: var(--culti-black);
        }

        &.hidden {
            display: none;
        }

        &.focus {
            display: inline-block;
            background-color: white;
            margin: 0.5rem 0;
            padding: 0.875rem 0.5rem;
            border: 1px solid var(--default-border-color);
            border-radius: 5px;
            text-overflow: unset;
        }


        &--editable {
            white-space: nowrap;

            &:hover {
                cursor: text;
            }
        }

        &.disabled {
            color: var(--culti-darker-grey);
            &:hover {
                color: var(--culti-darker-grey);
                cursor: not-allowed;
            }
        }

        &--sub-text {
            font-weight: normal;
        }
    }

    &__tools {
        display: flex;
        align-items: center;
        margin-left: auto;
    }


    &__edit {
        margin-right: 0.5rem;
        display: none;
        outline: none;
        border: 0;
        background: none;

        &:active, &:focus {
            outline: none;
        }

        &.show {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }

    &__edit-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__delete {
        margin-right: 0.5rem;
        display: none;
        outline: none;
        border: 0;
        background: none;

        &:active, &:focus {
            outline: none;
        }

        &.show {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }

    &__delete-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__lock {
        display: none;
        align-items: center;

        &.disabled {
            display: flex;
        }
    }

    &__lock-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    &__arrow-icon {
        width: 0.75rem;
        height: 0.75rem;
        display: none;

        &.show {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }

    &__bars {
        font-size: 0.875rem;
        display: none;

        &.show {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }

    &__bars-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }


    &__save {
        display: none;
        background-color: var(--culti-green);
        margin: 0 0.5rem 0.5rem 0;
        padding: 0.3rem 0.75rem;
        font-size: 0.75rem;

        &.show {
            display: inline-flex;
        }
    }

    &__discard {
        display: none;
        font-weight: 600;
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin-bottom: 0.5rem;

        &:hover {
            text-decoration: underline;
        }

        &.show {
            display: inline-flex;
        }
    }

    &__textarea {
        display: none;

        &.show {
            display: block;
        }
    }

    &__eta {
        font-size: 0.7rem;
        font-weight: 500;
        color: var(--culti-black);
    }

    &__border-export {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        width: 100%;

        .input {
            width: 40%;
            margin-right: 0.5rem;
        }

        select {
            margin-bottom: 0rem;
        }

        button {
            width: 55%;
            margin-left: 0.5rem;
        }
    }

    &__agrirouter-export {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        width: 100%;

        &.hidden {
            display: none;
        }

        .input {
            width: 100%;
        }

        select {
            margin-bottom: 0rem;
        }

        button {
            width: 100%;
        }
    }

    &__thumbnail {
        display: flex;
        position: relative;
        align-items: center;
        margin-right: 0.5rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 3rem;
        width: 3rem;
        flex-shrink: 0;
    }
}
