.c-landing-topnav {
    font-weight: 600;
    color: var(--culti-black);
    background: white;
    top: 0;
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    top: -10000px;
    left: 0;
    right: 0;
    padding: 1rem 2rem 2rem 2rem;
    overflow-y: auto;

    &[data-state~="opened"] {
        top: 6.75rem;
        height: calc(100vh - 6.75rem);
    }

    &[data-state~="opened"] {
        &[data-scroll~="true"] {
            top: 3.75rem;
            height: calc(100vh - 3.75rem);
        }
    }

    @media #{$screen-992} {
        top: 86px;
        height: 100%;
        position: static;
        flex-direction: row;
        padding: 0rem;
        overflow: visible;
        width: 60%;

        &[data-state~="opened"] {
            top: 0;
            height: unset;
        }

        &[data-state~="opened"] {
            &[data-scroll~="true"] {
                top: 0;
                height: unset;
            }
        }
    }
}
