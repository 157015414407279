.c-submenu {
    display: none;
    border: none;
    list-style: none;
    flex-direction: column;
    background-color: white;
    padding: 0.5rem 0;
    align-items: center;
    border-radius: 5px;

    &[data-state~="opened"] {
        display: flex;
    }

    @media #{$screen-1200} {
        min-width: 10rem;
        box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.15);
        position: absolute;
        bottom: -1rem;
        right: 0;
        transform: translateY(100%);
    }

    &__item {
        margin-bottom: 0.5rem;

        @media #{$screen-1200} {
            width: 100%;
            margin-bottom: 0;
        }
    }

    &__link {
        font-size: 14px;
        font-weight: 600;
        display: block;
        width: 100%;
        clear: both;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        &:hover, &:active {
            color: var(--culti-black);
            background-color: var(--culti-white);
        }

        @media #{$screen-1200} {
            font-size: 0.75rem;
            padding: 0.25rem 1.5rem;

            &:hover, &:active {
                color: var(--culti-black);
                background-color: var(--culti-light-grey);
            }
        }
    }

    &__divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        display: none;
        border-top: 1px solid #e9ecef;


        @media #{$screen-1200} {
            display: block;
        }
    }
}
