.c-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  &__text {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 0;

    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  &:hover &__text {
    visibility: visible;
    opacity: 1;
  }

}