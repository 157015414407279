.c-hamburger {
    cursor: pointer;
    caret-color: transparent;
    margin-right: 10px;

    &--landing {
        display: flex;
        flex: 1;
        justify-content: end;
        align-items: center;

        @media #{$screen-992} {
            display: none;
        }
    }

    &--portal {
        display: block;

        @media #{$screen-1200} {
            display: none;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 25px;

        span {
            border-radius: 3px;
            height: 3px;
            margin-bottom: 4px;
            transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1) {
            margin-left: auto;
            background: var(--culti-dark-green);
            width: 50%;
        }

        span:nth-of-type(2) {
            margin-left: auto;
            background: var(--culti-green);
            width: 75%;
        }

        span:nth-of-type(3) {
            margin-left: auto;
            background: var(--culti-green);
            width: 100%;
        }
    }

    &[data-state~="opened"] {
        span:nth-of-type(1) {
            transform-origin: bottom;
            transform: rotatez(-45deg)
        }

        span:nth-of-type(2) {
            width: 50%;
            transform-origin: bottom;
            transform: rotatez(-45deg) translate(-8px, -5px);
        }

        span:nth-of-type(3) {
            transform-origin: bottom;
            width: 100%;
            transform: translate(0px, -10px) rotatez(45deg);

        }

    }
}
