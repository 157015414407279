.c-uploads {
    &__title {
        text-align: center;
        font-size: 1.5rem;
        color: var(--culti-black);
        margin-bottom: 3rem;
    }

    &__blocks {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    &__block {
        display: flex;
        margin: 2.5rem;
        align-items: center;
        min-width: 18.5rem;
        max-width: 18.5rem;
        background-color: var(--culti-white);
        padding: 2rem 1.5rem;
        border-radius: 0.5rem;
        box-shadow: var(--default-box-shadow);
    }

    &__block-image {
        margin-right: 1.5rem;
    }

    &__block-title {
        font-style: normal;
        color: var(--culti-black);
        margin-bottom: 0rem;
    }

    &__form {
        width: 100%;
    }

    &__steps-wrapper {
        margin: 0 auto 3rem auto;
        padding: 1rem 2rem;
        border-radius: 1rem;
        width: 100%;
        max-width: 80%;
        box-shadow: var(--default-box-shadow);
    }

    &__steps {
        position: relative;
        list-style: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        &:before {
            content: '';
            position: absolute;
            border-bottom: 3px solid var(--culti-green);
            width: calc(100% - 16rem);
            top: 23px;
            z-index: 0;
            left: 8rem;
        }
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 12rem;
        z-index: 2;
    }

    &__step-number-wrapper {
        padding: 0 0.5rem;
        background-color: white;
    }

    &__step-number {
        font-size: 1.125rem;
        font-style: italic;
        font-weight: 600;
        background-color: var(--culti-white);
        color: var(--culti-black);
        padding: 1.125rem 1rem;
        border: 2px solid var(--culti-green);
        border-radius: 50%;
        width: 3.125rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;

        &.active {
            background-color: var(--culti-green);
            color: var(--culti-white);
        }
    }

    &__step-text {
        font-size: 0.875rem;
        margin-bottom: 0rem;
        text-align: center;
    }

    &__tabs {
        min-height: 18.75rem;
    }

    &__tab {
        margin: 2.5rem 0 1.5rem 0;
        display: none;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -2rem;

        &.hidden {
            display: none;
        }
    }

    &__button {
        font-size: 0.75rem;
        font-weight: 600;
        display: block;
        padding: 0.375rem 1.25rem;
        text-decoration: none;
        border-radius: 5px;
        margin-left: 2rem;

        &:hover,
        &:active,
        &:focus {
            outline: none;
        }

        &--prev {
            background: var(--culti-white);
            color: var(--culti-black);
            border: 1px solid var(--culti-black);
        }

        &--next {
            background: var(--culti-green);
            color: var(--culti-white);
            border: 1px solid transparent;
        }

        &.disabled {
            background-color: var(--culti-light-grey);
            color: #aaa;
            border: 1px solid #aaa;
        }
    }

    b {
        font-style: normal;
        color: var(--culti-green);
        font-weight: 600;
    }

    &__container {
        padding-left: 2em;
        padding-right: 2em;
    }

    &__reminders {
        margin-top: 2rem;
        margin-bottom: 0;
        text-align: center;
    }

    &__description {
        margin-bottom: 2em;
        text-align: center;
    }

    &__step-container-narrow {
        max-width: 18rem;
        margin: 0 auto 0 auto;
    }

    // Step 1 styles
    // EMPTY

    // Step 1.5

    &__method-select-container {
        text-align: center;
    }

    // Step 2 and 3 common styles

    &__file-list {
        text-align: center;
    }

    &__download {
        color: var(--culti-green);
        font-weight: bold;
        text-decoration: none;

        &:hover,
        &:active {
            color: var(--culti-green);
        }
    }

    &__download-icon {
        margin-right: 0.5rem;
    }

    // Step 2 styles

    &__drop-area {
        border: 2px dashed #ccc;
        border-radius: 20px;
        margin: 1em;
        padding: 1em;
        max-width: calc(50% - 2em);
        height: min-content;
    }

    &__drop-area--center {
        border: 2px dashed #ccc;
        border-radius: 20px;
        padding: 1em;
        max-width: calc(50% - 2em);
        height: min-content;
        margin: 1em auto;
    }

    &__drop-area.highlight {
        border-color: var(--culti-green);
    }

    &__file-input {
        display: none !important; // needed to precede jquery-steps styles
    }

    &__file-input-label {
        margin: 1em auto 0 auto;
        font-style: normal;
        width: fit-content;
    }

    &__file-counter {
        margin-bottom: 1em;
    }

    &__select-list {
        overflow-y: auto;
        max-height: 20em;
        width: 100% !important; // needed to precede jquery-steps styles
    }

    &__delete-file {
        cursor: pointer;
    }

    &__remove-all {
        text-decoration: underline;
        cursor: pointer;
    }

    // Step 3 styles

    // using this syntax, because can't add class to some CakePHP generated elements
    // {@function $this->Form->control()}
    .checkbox label {
        display: block;
        text-align: center;
    }

    &__checkbox-container {
        margin: 0 auto 1.5rem auto;
        width: max-content;
        text-align: center;
    }

    &__checkbox-container div.input {
        display: inline-block;
    }

    &__notes-bullets {
        font-size: 0.875em;
    }

    &__specific-item {
        display: block;

        &.hidden {
            display: none;
        }
    }

    // Upload progress styles

    &__warning {
        margin-bottom: 1.5rem;
    }

    &__processing {
        text-align: center;
        margin-bottom: 1em;
    }

    &__spinner {
        margin-right: 0.3em;
    }

    &__alert-box {
        display: inline-block;
        margin-bottom: 0.8em;
    }

    &__progress-container {
        margin-top: 2em;
    }

    &__total-progress {
        margin-bottom: 2em;
    }

    &__progress-list {
        width: 100% !important; // needed to precede jquery-steps styles
        overflow-y: auto;
        max-height: 20em;
    }

    &__progress-bar {
        border-radius: 4px;
        margin: 0 0.5em 1em 0.5em;
        width: calc(100% - 1em);
        height: 18px;
        border: 1px solid var(--culti-dark-grey);
    }

    &__meter {
        color: #fff;
        line-height: 18px;
        font-size: 12px;
        background-color: var(--culti-green);
        width: 0;
        height: 100%;
    }

    &__percentage {
        text-align: left;
        padding-left: 0.2em;
    }

    &__status-header {
        display: block;
        font-weight: 600;
    }

    &__message {
        text-align: center;
    }

    &__link {
        color: var(--culti-green);
        text-decoration: underline;
        transition: all 0.4s;

        &:hover,
        &:active {
            color: var(--culti-green);
            text-decoration: none;
        }
    }

    &__item-group {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.25rem;
        align-items: center;
        width: 40%;

        &:last-of-type {
            margin-bottom: 0rem;
        }
    }

    &__radio-wrapper {
        display: flex;
        align-items: center;
    }

    &__radio {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem !important;
    }

    &__label {
        margin-bottom: 0 !important;
    }

    &__note {
        font-size: 0.625rem;
        font-style: italic;
        text-align: center;
        margin-top: 0.5rem;
    }

    dialog {
        & span.hidden,
        & div.hidden,
        & p.hidden {
            display: none;
        }
    }
}
