.c-spinner {
    display: none;

    &.disabled {
        display: block;
    }

    &.visible {
        display: block;
        text-align: center;
    }

    &.visible-inline {
        display: inline-block;
        margin: auto 0 auto 1em;
    }

    &__border {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: text-bottom;
        border: 0.25em solid var(--culti-darker-grey);
        border-right-color: var(--culti-darker-grey);
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border 0.75s linear infinite;
        animation: spinner-border 0.75s linear infinite;
    }

    &__span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}
