.c-portal-menu {
    list-style: none;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    caret-color: transparent;

    &--right {
        @media #{$screen-1200} {
            margin-left: auto;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        align-items: center;

        @media #{$screen-1200} {
            flex-direction: row;
        }
    }

    &__item {
        margin: 0 0 1.25rem 0;

        @media #{$screen-1200} {
            margin: 0 0.75rem;
        }

        @media #{$screen-1400} {
            margin: 0 1rem;
        }
    }

    &__link {
        text-decoration: none;
        background-color: transparent;
        color: var(--culti-black);
        font-weight: 600;
        transition: all 0.3s;
        border-bottom: 2px solid transparent;
        font-size: 18px;
        margin: 0 auto;
        width: max-content;
        padding: 0;

        &:hover,
        &:active &:focus {
            color: var(--culti-green);
        }

        @media #{$screen-1200} {
            font-size: 13px;
            padding: .5em 0 .2em 0;

            &:hover,
            &:active &:focus {
                border-bottom: 2px solid var(--culti-hover);
                color: var(--culti-black);
            }

        }
    }

    &__dropdown-item {
        margin: 0 0 1rem 0;
        position: relative;

        @media #{$screen-1200} {
            margin: 0 0.5rem;
        }

        @media #{$screen-1400} {
            margin: 0 1rem;
        }
    }

    &__dropdown-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            display: flex;
            align-items: center;
            margin-left: 0.4rem;
        }
    }

    &__button-wrapper {
        margin: 0 0 1.25rem 0;

        @media #{$screen-1200} {
            margin: 0 0.5rem;
        }

        @media #{$screen-1400} {
            margin: 0 1rem;
        }
    }

    &__button {
        font-size: 1rem;
        display: flex;
        align-items: center;

        @media #{$screen-1200} {
            font-size: 0.8125rem;
        }
    }

    &__dropdown-link {
        font-weight: 400;
        font-style: italic;
        border-bottom: 2px solid transparent;
        margin: 0 0.5rem 0 0;
        font-size: 1rem;

        @media #{$screen-1200} {
            font-size: 13px;
            margin: 0 0.5rem 0 0;
        }
    }

    &__link-icon {
        width: 1.5rem;

        @media #{$screen-1200} {
            width: 1.625rem;
        }
    }
}
