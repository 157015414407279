.c-portal-body {
    &.prevent-scroll {
        margin: 0;
        height: 100%;
        overflow: hidden;

        @media #{$screen-1200} {
            height: unset;
            overflow: visible;
        }
    }
}
