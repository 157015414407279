@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap);

body {
    font-family: $default-font;
    font-size: 12px;
}

.heading-h1 {
    font-size: 1.25rem;
    font-weight: 800;
    color: var(--culti-black);
    line-height: 1.35;
    margin-bottom: 2rem;

    &--center {
        margin: 0 auto 2rem auto;
        text-align: center;
    }

    &--nomargin {
        margin-bottom: 1.5rem !important;
    }

    @media #{$screen-420} {
        font-size: 1.75rem;
        margin-bottom: 4rem;

        &--center {
            margin: 0 auto 4rem auto;
            text-align: center;
        }
    }

    @media #{$screen-992} {
        font-size: 2.375rem;
        margin-bottom: 4.5rem;

        &--center {
            margin: 0 auto 4.5rem auto;
            text-align: center;
        }
    }
}

.polygon {
    position: relative;

    &::after {
        display: none;
    }

    @media #{$screen-420} {
        &::after {
            display: block;
            position: absolute;
            user-select: none;
            width: 6.5rem;
            height: 6.5rem;
        }
    }

    @media #{$screen-992} {
        &::after {
            width: 8.5rem;
            height: 8.5rem;
        }
    }
}

.heading-h2 {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--culti-black);
    line-height: 1.4;
    margin-bottom: 1.5rem;

    @media #{$screen-420} {
        line-height: 1.7;
        font-size: 1rem;
    }

    @media #{$screen-992} {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }
}

.heading-h3 {
    display: inline;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1.2;
    text-align: left;
    color: var(--culti-green);
}

.text {
    &--medium {
        font-size: 0.875rem;
        line-height: 1.8;

        b {
            font-weight: 600;
        }
    }

    &--small {
        font-size: 0.875rem;
        line-height: 1.7;
    }

    @media #{$screen-420} {
        &--medium {
            font-size: 1rem;
        }

        &--small {
            font-size: 1rem;
        }
    }

    @media #{$screen-992} {
        &--medium {
            font-size: 1.125rem;
        }

        &--small {
            font-size: 1.125rem;
        }
    }
}

.culti-content-header {
    width: 50%;
    padding: 1.125rem 0;
    text-align: center;
    background-color: var(--culti-light-grey);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 0 auto 12px auto;
}

.culti-content-header h1 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 700;
}

.cultiwise-header {
    padding-top: 0 !important;
}

.green-text {
    color: var(--culti-green);
}


/*Settings for portal, that's just temporary solution, so i don't have to overwrite 50 files
this is not best practice 
don't do this on your project
*/

h1 {
    font-size: 1.25rem;
    font-weight: 800;
    color: var(--culti-black);
    line-height: 1.35;
    margin-bottom: 2rem;


    @media #{$screen-420} {
        font-size: 1.75rem;
        margin-bottom: 4rem;

    }

    @media #{$screen-992} {
        font-size: 2.375rem;
        margin-bottom: 4.5rem;
    }
}

h2 {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--culti-black);
    line-height: 1.4;
    margin-bottom: 1.5rem;

    @media #{$screen-420} {
        line-height: 1.7;
        font-size: 1rem;
    }

    @media #{$screen-992} {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }
}

h3 {
    display: inline;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1.2;
    text-align: left;
    color: var(--culti-green);
}
