.c-sidenav-user-selection {
    background-color: var(--culti-dark-green);
    box-shadow: 0.5px 3.6px 5.6px 0 rgba(51, 51, 51, 0.15);
    padding: 1em;
    z-index: 25;
    width: var(--sidenav-width);
    color: white;

    &__dropdown {
        margin: 0rem;
    }

    select {
        color: var(--culti-white);

        &:focus {
            border-color: var(--culti-white);
        }

        option {
            color: var(--culti-dark-green);
        }
    }
}
