.c-map-edit {
    display: none;
    position: absolute;
    left: 0px;
    bottom: 40px;
    background-color: white;
    border-radius: 1rem;
    padding: 3rem;
    max-width: 500px;
}
