/* General styles */
//TODO get rid of this completely
//Never use global selectors like this, wihtout bounding it to certain block

form {
    color: var(--culti-black);
}

input {
    width: 100%;
    height: 2em;
    border: none;
    border-bottom: 1px solid var(--default-border-color);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 2.2em;
    padding: .3em .3em;
    transition: border .1s;
    font-weight: 500;
    background-color: transparent;
}

input:focus {
    border-color: var(--culti-green);
    border-width: 2px;
    outline: none;
}

input::placeholder {
    color: #999;
}

/* Select boxes */

select {
    width: 100%;
    height: 2em;
    border: none;
    border-bottom: 1px solid var(--default-border-color);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 2.2em;
    transition: border .1s;
    font-weight: 500;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(../img/icon-arrow-down.svg) no-repeat right;
    background-size: 1em;
}

select[multiple] {
    height: auto;
}

select:focus {
    border-color: var(--culti-green);
    border-width: 2px;
    outline: none;
}


/* Textareas */

textarea {
    min-width: 60%;
    width: 100%;
    min-height: 10em;
    border: 1px solid var(--default-border-color);
    box-shadow: none;
    transition: border .1s;
    margin-bottom: 2.2em;
    padding: .2em;
    font-weight: 500;
}

textarea:focus {
    border-color: var(--culti-green);
    border-width: 2px;
    outline: none;
}

textarea::placeholder,
input::placeholder {
    opacity: 1;
    color: #bbb;
}


/* Radio buttons & checkboxes */
/* Use divs to put each radio/checkbox on new line */

input[type="radio"],
input[type="checkbox"] {
    margin: 0 .2em 0 .4em;
}

/* File upload input field */
input[type="file"] {
    border: none;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
}

/* Labels and legends */

label {
    font-size: .9em;
    font-style: italic;
    margin-bottom: 3px;
}

legend {
    font-size: 1em;
    font-weight: 500;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content .map {
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 1em;
    left: 0;
    top: 0;
}
