.c-sidenav-calendar {
    padding: 0.625rem 0rem;
    cursor: default;
    caret-color: transparent;

    &__month-slider {
        display: flex;
        background-color: var(--culti-dark-green);
        align-items: center;
        padding: 0.375rem 0.375rem;
        border-radius: pxToRem(5px) pxToRem(5px) 0 0;
    }

    &__month-slide {
        width: 100%;
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--culti-white);
        text-align: center;
    }

    &__btn {
        &:hover {
            cursor: pointer;
        }
    }

    &__arrow {
        width: 0.875rem;
        height: 0.875rem;
    }

    &__week-days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 0.625rem;
        row-gap: 0.625rem;
        margin: 0.25rem 0 0.5rem 0;
    }

    &__week-day {
        color: #6d6d6d;
        font-weight: 500;
        text-align: center;
        padding: 0.25rem;
    }

    &__days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 0.625rem;
        row-gap: 0.3rem;
        margin-bottom: 0.5rem;
    }

    &__day {
        display: flex;
        flex-direction: column;
        align-items: center;

        &[data-has-events='true'] {
            &:hover {
                cursor: pointer;
            }
        }

        &--highlighted {
            position: relative;
            z-index: 1;
            color: white;

            &:before {
                position: absolute;
                z-index: -1;
                top: -0.2rem;
                left: auto;
                width: 1.3rem;
                height: 1.3rem;
                content: "";
                border-radius: 50%;
                background-color: var(--culti-green);
            }
        }
    }

    &__day-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.25rem;
    }

    &__day-events {
        width: 100%;
        height: 0.5rem;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    &__day-event {
        width: 100%;
        height: 0.2rem;
        border-radius: 0.1rem;

        &--sentinel {
            background-color: var(--sentinel-green);
        }

        &--planet {
            background-color: var(--planet-green);
        }
    }

    &__legend {
        display: flex;
        flex-wrap: wrap;
    }

    &__legend-item {
        font-size: 0.625rem;
        display: flex;
        align-items: center;
        margin-right: 0.5rem;

        &--sentinel {
            &::before {
                content: '';
                width: 1.5rem;
                height: 0.2rem;
                border-radius: 0.1rem;
                background: var(--sentinel-green);
                margin-right: pxToRem(5px);
            }
        }

        &--planet {
            &::before {
                content: '';
                width: 1.5rem;
                height: 0.2rem;
                border-radius: 0.1rem;
                background: var(--planet-green);
                margin-right: pxToRem(5px);
            }
        }
    }

    &__item {
        display: none;

        &.show {
            display: block;
        }
    }

    &__cloud {
        margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__cloud-text {
        &--sentinel, &--planet {
            font-size: 0.625rem;
            font-weight: bold;
        }
    }

    &__cloud-image {
        width: 1.25rem;
        height: auto;
    }

    &__list {
        list-style: none;
        margin: 0;
    }
}

#calendar-hide-button {
    display: none;
}
