.c-sidenav {
    height: 100vh;
    z-index: 1038;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 4.375rem;

    &__color-picker {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 1.25rem;
        overflow: hidden;
        border: 1px solid var(--culti-dark-grey);
        opacity: .7;
    }

    &__color {
        border: 0;
        padding: 0;
        width: 200%;
        height: 200%;
        cursor: default;
        transform: scale(2);
    }

    .hidden {
        display: none;
    }

    .checkbox-advanced {
        display: inline-flex;
        align-items: center;
    }

    .checkbox-advanced label {
        display: inline-block;
        margin-left: 3px;
        margin-top: 4px;
        font-weight: bolder;
        font-size: 0.625rem;
    }
}
