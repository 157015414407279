.c-map-controls {
    position: absolute;
    top: 5rem;
    right: 2rem;
    /* Based on Leaflet and OpenLayers controls styles */
    width: 100px;

    &__panel {
        border-radius: 1.125rem;
        padding: 1.6em .7em;
        background-color: var(--culti-white);
        font-size: 0.6875rem;
        font-style: italic;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__title {
        font-size: 0.75rem;
        font-weight: 600;
        font-style: normal;
        text-align: center;
    }

    &__line {
        width: 100%;
    }

    &__table {
        width: 100%;

        input {
            font-size: 0.6875rem;
            width: 1rem;
            height: 1rem;
            border: none;
            border-bottom: 1px solid var(--default-border-color);
            border-radius: 0;
            box-shadow: none;
            transition: border .1s;
            font-weight: 500;
            background-color: transparent;
            margin-bottom: 1rem;
        }

        td {
            text-align: center;

            &:last-child {
                font-size: 0.5rem;
            }
        }

        img {
            width: 2rem;
            max-height: 1.3rem;
        }
    }

    &__geolocation {
        width: 100%;
        height: 2.25rem;
        margin-bottom: 1rem;
    }

    &__geolocation-button {
        font-size: 0.9em;
        font-style: italic;
        display: flex;
        align-items: center;
        padding: 0rem 0.5rem;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        outline: none;
        border: none;
        background-color: white;

        &:active, &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__geolocation-icon {
        margin-left: auto;
        width: 1rem;
        height: 1rem;
    }
}

#drawing-tools-controls {
    display: none;
}

