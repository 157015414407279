/* The sliders */

.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 30px;
    /* Specified height */
    background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 244, 0, 1) 50%, rgba(26, 255, 0, 1) 100%);
    outline: none;
    /* Remove outline */
}


/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: #4CAF50;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 20px;
    /* Set a specific slider handle width */
    height: 30px;
    /* Slider handle height */
    background: #FFFFFF;
    /* Green background */
    opacity: 0.6;
    cursor: pointer;
    /* Cursor on hover */
}
