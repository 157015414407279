.c-checkbox-tick {
    font-size: 0.625rem;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    &__input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0 !important;

        &:checked ~ .c-checkbox-tick__checkmark {
            background-color: var(--culti-green);
        }
    }

    &__checkmark {
        position: relative;
        height: 1.125rem;
        width: 1.125rem;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid var(--culti-green);
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;

        svg {
            position: absolute;
            width: 0.625rem;
        }
    }

}


.c-checkbox-radio {
    display: flex;
    align-items: center;
    justify-content: center;

    &.hidden {
        display: none;
    }

    &.disabled {
        display: flex;

        .c-checkbox-radio__checkmark {
            border: 2px solid var(--culti-darker-grey);
            &:after {
                background: var(--culti-darker-grey);
            }
        }
    }

    &__checkmark {
        position: relative;
        cursor: pointer;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid var(--culti-green);
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";
            display: none;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: var(--culti-green);
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: auto;
        height: auto;
        margin: 0 !important;

        &:checked~.c-checkbox-radio__checkmark {
            background-color: transparent;
        }

        &:checked~.c-checkbox-radio__checkmark:after {
            display: block;
        }
    }
}


