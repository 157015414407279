:root {
  /* COLORS */
  --culti-green: #26A659; /* rgb(129, 182, 80) */
  --culti-hover: #208C4B;
  --sentinel-green: var(--culti-hover);
  --planet-green: #F75C3A;
  --culti-dark-green: #203B30;
  --culti-red: #a81227;
  --culti-black: #323540; /* rgb(50, 53, 64) */
  --culti-light-grey: #f4f4f4;
  --culti-menu-font-color: #302f31bf;
  --culti-new-dark: #302f31;
  --culti-footer-green: #274936;
  --culti-dark-grey: #e0e0e0;
  --culti-darker-grey: #c5c5c5;
  --culti-white: #ffffff;
  --default-border-color: #{rgba(0, 0, 0, .2)};
  --default-box-shadow: 0 0.25rem 1rem 0 rgba(19, 82, 53, 0.15);

  /* SIZES */
  --sidenav-width: 330px;
}
