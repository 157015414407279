.c-sidenav-histogram {
    &__label {
        font-size: 0.625rem;
    }

    &__select {
        font-size: 0.75rem;
        margin-bottom: 1.5rem !important;
    }

    &__settings {
        position: relative;
    }

    &__settings-button {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        outline: initial;
        z-index: 1;
        border: 0;

        &:focus {
            outline: initial;
        }
    }

    &__settings-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0;
    }

    &__menu {
        padding-top: 1.5rem;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        padding: 0;
        list-style-type: none;
    }

	&__list-field-trials {
		list-style: none;
		display: flex;
		flex-direction: column;
	}

    &__item {
        display: flex;
        align-items: center;
    }

	&__item-field-trials {
		margin-bottom: 0.5rem;
		display: flex;
		flex-direction: column;
		align-items: start;
	}

    &__label {
        margin-bottom: 0.3rem;
    }

    &__checkbox {
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

	&__checkbox-field-trials {
		font-size: 0.625rem;
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}

    &__input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0 !important;

        &:checked ~ .c-sidenav-histogram__checkmark {
            background-color: var(--culti-green);
        }
    }

    &__checkmark {
        position: relative;
        height: 1.125rem;
        width: 1.125rem;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid var(--culti-green);
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.25rem;

        svg {
            position: absolute;
            width: 0.625rem;
        }
    }

    &__svg {
        display: flex;
        justify-content: center;
    }

    &__slider {
        margin-bottom: 1.5rem;
    }

    &__svg {
        display: flex;
        justify-content: center;
    }

    &__slider {
        margin-bottom: 1.5rem;
    }

    &__custom-slider {
        margin-top: 0.7rem;
        background: #fff;
        border: 1px solid #ccc;
        text-align: center;

        .ui-slider-handle {
            width: 2.8em;
            height: 1.8em;
            line-height: 1.6em;
            text-align: center;
            background-color: #f6f6f6;
            border: 1px solid #cccccc;
            border-radius: 0.5em;
            position: absolute;
            top: -0.6em;
        }

        .ui-state-active {
            background-color: #ffffff;
            color: #1b1e21;
            border: 1px solid #cccccc;
        }

    }
}
