.section {
    padding: 3rem 0rem;

    @media #{$screen-768} {
        padding: 4rem 0rem;
    }

    @media #{$screen-992} {
        padding: 6rem 0rem;
    }

    &--wider {
        max-width: none;
    }

    &--top {
       padding-top: 14.25rem;
    }

    &--nopad {
        padding: 0rem;
    }
}
