.c-sidenav-legend-table {
    word-wrap: break-word;
    width: 100%;

    thead {
        border-bottom: 2px solid #ddd;
    }

    tfoot {
        tr {
            border-top: 2px solid #ddd;
        }

        th {
            text-align: left;
        }
    }

    td, th {
        padding: 0.5em 1em;
        text-align: center;
    }
    
    // make the color column narrower
    td:first-of-type, th:first-of-type {
        padding: 0.5em;
    }

    td {
        &.focus {
            background-color: white;
        }
    }
    
    input {
        margin: auto;
        text-align: center;
    }
}