.c-map-legend {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var(--culti-white);
    font-size: 0.75rem;
    font-style: italic;
    font-weight: bold;

    &.show {
        display: block;
    }

    @media #{$screen-992} {
        left: var(--sidenav-width);
        width: calc(100% - var(--sidenav-width));
    }

    &__gradient {
        height: 0.75rem;
        text-align: right;
        line-height: 1;
        padding-right: .5em;
    }

    &__values {
        background: rgba(0, 0, 0, .2);
        height: 1.375rem;
        padding: 0 .5em;
        margin: auto 0;
    }

    &__table {
        width: 100%;
        font-size: 0.5rem;
        color: white;

        td {
            text-align: center;
            width: 25%;
            padding-bottom: .4em;
            font-size: 0.75rem;

            &:first-child {
                text-align: left;
                width: 12.5%;
            }

            &:last-child {
                text-align: right;
                width: 12.5%;
            }
        }
    }
}
