.c-alert__flash {
  position: fixed;
  width: 70vw;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.25rem;
  z-index: 10000;

  &--hidden {
    display: none;
  }
}

