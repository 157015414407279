.c-frontend-flash {
    &__container {
        position: fixed;
        width: 70vw;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1.25rem;
        z-index: 10000;
    }

    &__element {
        margin-top: 1rem;
        height: auto;

        &--hidden {
            display: none;
        }
    }
}