.c-datepicker {
    padding: 0.625rem 2rem 0 2rem;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

    &__content {
        position: relative;
        max-width: 100%;
    }

    &__btn {
        cursor: pointer;
        position: absolute;
        bottom: 0px;
        display: block;
        background-color: var(--culti-green);
        width: 30px;
        height: 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &--left {
            left: 0;
            border-radius: 50% 0% 0% 50%;
        }

        &--right {
            right: 0;
            border-radius: 0% 50% 50% 0%;
        }
    }

    &__arrow {
        cursor: pointer;
        position: absolute;
        bottom: 35%;
        display: block;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        width: 10px;
        height: 10px;
      
        &--left {
            left: 35%;
            transform: rotate(-225deg);
        }

        &--right {
            right: 35%;
            transform: rotate(-45deg);
        }
    }

    &__dates {
        margin: 0px 30px 0px 30px;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        white-space: nowrap;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        line-height: 30px;
        background-color: white;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
            width: 0px;
            background: transparent; /* Chrome/Safari/Webkit */
        }
    }

    &__date {
        cursor: pointer;
        color: black;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        min-width: 100px;
        height: 30px;
        border-left: solid 1px #dddddd;
        border-right: solid 1px #dddddd;

        &:hover {
            color: black;
            text-decoration: none;
            background-color: var(--culti-dark-grey);
        }

        &.active {
            color: white;
            background-color: var(--culti-green);

            &:hover {
                color: white;
                background-color: var(--culti-green);
            }
        }
    }
}