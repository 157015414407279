.c-yield-potential {
    &__container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 8.5rem;
        left: 0;
        width: 100%;
        height: calc(100% - 10rem);
    }

    &__top {
        height: 70%;
        max-width: 100%;
        background-color: var(--culti-light-grey);
        margin: 1rem;
        border-radius: 20px;
    }

    &__avg {
        height: calc(80% - 2rem);
        width: calc(100% - 2rem);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: 1rem;
    }

    &__legend {
        height: 10%;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &__legend_gradient {
        height: 0.5rem;
        width: 100%;
        background-color: green;
        background-image: linear-gradient(to right, red, yellow, green);
        border-radius: 0.5rem;
    }

    &__legend_label_min {
        float: left;
        width: 50%;
    }

    &__legend_label_max {
        float: right;
        text-align: right;
        width: 50%;
    }

    &__field-nav{
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 10%;
        width: 100%;
    }

    &__field-btn-wrapper{
        text-align: center;
        min-width: 40%;
    }
    
    &__field-info-wrapper {
        text-align: center;
        min-width: 20%;
        font-size: 1rem;
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        height: 30%;
        max-width: 100%;
        background-color: var(--culti-light-grey);
        margin: 1rem;
        border-radius: 20px;
    }

    &__individual-years {
        display: flex;
        flex-direction: column;
        height: calc(100% - 2rem);
        width: calc((100% - 2rem) / 6);
        margin: 1rem;
    }

    &__year-label {
        height: 10%;
        width: 100%;
        font-size: 1rem;
    }

    &__year-img {
        height: 90%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}