dialog {
    width: 600px;
    font-size: 20px;
    border: none;
    border-radius: 0.5rem;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::backdrop {
        background-color: rgb(0 0 0 / 70%);
    }

    & form {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 24px;

        & h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        & p {
            flex-grow: 1;
            font-size: 0.75rem !important;
        }

        & .c-dialog__button-wrapper {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            & button {
                font-size: 0.75rem;
                margin: 0;

                &.c-btn-secondary {
                    display: block;
                }
            }
        }
    }
}
