.c-wrapper {
    padding: 0 1rem;
    width: 90rem;
    max-width: 100%;
    margin: 0 auto;


    @media #{$screen-768} {
        padding: 0 2rem;
    }

    @media #{$screen-1200} {
       padding: 0 4rem;
    }
}