.c-login__container {
    min-height: calc(100vh - 150px);
    padding-top: 156px;
}

.c-login {
    flex-grow: unset;
    margin-bottom: 4rem;
    
    input, select {
        margin-bottom: 0;
    }
    
    main {
        display: block;
        min-width: 300px;
        margin: 1rem auto 3rem auto;
        text-align: center;
    }

    a {
        color: var(--culti-green);
    }

    a:hover {
        color: var(--culti-dark-green);
    }

    form {
        margin: 2rem auto;
        text-align: left;
    }

    // div generated by cakephp if backend form validation fails
    .error-message {
        color: var(--culti-red);
    }

    // class generated by cakephp Form helper
    .input {
        margin-bottom: 1.65rem;
    }

    // overriding style for bootstrap class specifically in this context
    .col-lg-6 {
        padding: 1em 2em;
    }
    
    &__add-user {
        max-width: 700px;
    }

    &__login {
        width: 20vw;
    }
    
    &__toggles {
        width: 20vw;
        min-width: 300px;
        margin: 0 auto;
        
        a {
            color: var(--culti-black);
        }
        
        a:hover {
            color: var(--culti-black);
        }
    }

    &__toggle, &__box {
        display: inline-block;
        text-align: center;
        background-color: var(--culti-light-grey);
        padding: .5em 1.7em;
        border-radius: .5em;
        font-size: 14px;
        font-weight: 600;
    }
    
    &__box {
        width: 100%;
        padding: .8em;
        margin-top: .5em;
        font-size: 16px;
    }
    
    &__toggle {
        width: 49%;
    }

    &__toggle-active {
        background-color: var(--culti-black);
        color: var(--culti-white);
    }

    &__toggle:first-child {
        margin-right: 1px;
    }

    &__reset-pwd {
        font-style: italic;
        font-size: .9em;
        color: var(--culti-black);
    }

    &__reset-pwd:hover {
        color: var(--culti-green);
    }
    
    &__terms {
        display: flex;
    }

    &__terms input {
        width: revert;
        height: revert;
        margin-right: .5em;
    }
    
    &__phone-input {
        margin-bottom: 2.2em;
    }
}
