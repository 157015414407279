.c-sidenav-block {
    width: var(--sidenav-width);
    overflow: hidden;
    position: relative;
    box-shadow: 0px 8px 16px rgba(179, 182, 185, 0.16);

    &--collapsible {
        width: 0px;
        transition: width 0.35s ease;

        &.collapsing {
            width: 0px;
            height: auto;
            overflow: hidden;
            transition: width 0.35s ease;

            h3,
            h4,
            label {
                white-space: nowrap;
            }

            p {
                white-space: pre-wrap;
            }
        }

       
        &.show {
            width: var(--sidenav-width);
        }
    }

    &__wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: var(--sidenav-width);
    }

    &__inner {
        padding-top: 1.25rem;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        background-color: var(--culti-white);
        border-top-right-radius: 0.5rem;

        &--title {
            padding-top: 0rem;
        }

        &--top {
            padding-top: 0rem;
            height: calc(100% - 4.7rem);
            border-top-right-radius: 0rem;
        }
    }

    &__top {
        position: relative;
        background-color: var(--culti-light-grey);
        padding: 1.25rem;
        text-align: left;
        border-top-right-radius: 0.5rem;
    }

    &__close {
        position: absolute;
        border: none;
        right: 0.75rem;
        top: 0.75rem;
        border-radius: 50%;
        background-color: white;
        padding: 0.5rem;
        line-height: 0;
        outline: none;

        &:active,
        &:focus {
            outline: none;
        }
    }

    &__close-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__title-wrapper {
        background-color: var(--culti-light-grey);
        padding: 1.125rem;
        border-top-right-radius: 0.5rem;
        margin-bottom: 0.625rem;
    }

    &__title {
        display: block;
        font-size: 0.875rem;
        font-weight: 600;
        font-style: normal;
        color: var(--culti-black);
        margin-bottom: 0.25rem;

        &--center {
            text-align: center;
        }
    }

    &__subtitle {
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--culti-green);
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
