.c-uploads-content {
    width: 100%;
    background-color: var(--culti-white);
    border-radius: 1rem;

    &__divider {
        margin: 2rem 0;
    }

    &__title-wrapper {
        margin-bottom: 1.5rem;
    }

    &__title {
        display: block;
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
        color: var(--culti-black);

        &--center {
            text-align: center;
        }
    }

    &__list {
        list-style: none;
        counter-reset: item;
    }

    &__item {
        counter-increment: item;
        margin-bottom: 5px;
        display: flex;

        &:before {
            content: counter(item) '.';
            font-size: 0.875rem;
            font-weight: bold;
            color: var(--culti-black);
            margin-right: 0.5rem;
        }
    }

    &__text {
        font-size: 0.875rem;
        color: var(--culti-black);
        margin-bottom: 0rem;

        a {
            color: var(--culti-green);
            font-weight: bold;

            &:hover {
                cursor: pointer;
            }
        }

        b {
            color: var(--culti-black);
            font-weight: bold;
        }
    }

    &__secret {
        width: 100%;
        position: relative;
        margin: 0.75rem 0 1.5rem 0;
    }

    &__secret-inner {
        display: flex;
        align-items: center;
    }

    &__secret-input-wrapper {
        position: relative;
    }

    &__secret-input {
        padding-right: 2.25rem;
        margin-bottom: 0rem;
    }

    &__secret-icon {
        position: absolute;
        right: 0.5rem;
        bottom: 0.25rem;

        &:hover {
            cursor: pointer;
        }

        svg {
            fill: var(--culti-green);
            width: 1.25rem;
            height: 1rem;
        }

        &:before {
            display: block;
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid var(--culti-green);
            transform: rotate(-40deg);
        }

        &.visible {
            &:before {
                display: none;
            }
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
    }

    &__copy-button {
        display: block;
        font-size: 0.75rem;
        font-weight: bold;
        color: var(--culti-white);
        padding: 0.125rem 1rem;
        margin: 0 0.5rem 0 0.25rem;
        border: none;
        border-radius: 0.4em;
        background-color: var(--culti-green);

        &:hover, &:active {
            outline: none;
            background-color: var(--culti-black);
        }
    }

    &__copy-message {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: opacity 0.3s;
      
        &.show {
            visibility: visible;
            height: auto;
            opacity: 0.75;
        }
    }

    &__checkbox {
        font-size: 0.875rem;
        font-style: normal;
        justify-content: left;
    }
}
