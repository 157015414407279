.c-search {
    margin: 1.5em auto;
    width: 100%;
    position: relative;
    padding-right: 0;

    &__input {
        font-style: italic;
        font-weight: 500;
        color: var(--culti-black);
        font-size: .9em;
        border: none;
        background-color: var(--culti-light-grey);
        border-radius: 7px;
        padding: 1em 1em;
        margin-bottom: 0;
    }

    &__icon {
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
        position: absolute;
        right: 0;
        top: 0.1rem;
    }
    
    &__send {
        cursor: pointer;
    }
}