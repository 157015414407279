.c-measurement-tooltip {
    position: relative;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    color: black;
    padding: 4px 8px;
    opacity: 0.75;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;

    &.hidden {
        display: none;
    }

    &--measure:before,
    &--static:before {
        border-top: 6px solid rgba(255, 255, 255, 0.95);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: "";
        position: absolute;
        bottom: -6px;
        margin-left: -7px;
        left: 50%
    }

    &--measure {
        opacity: 1;
        font-weight: 600;
    }

    &--static {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.95);
        color: black;
    }
}
