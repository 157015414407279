.c-footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__top {
        background-color: var(--culti-footer-green);
        color: var(--culti-white);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-evenly;
        padding: 37px;
        gap: 20px;

        @media #{$screen-992} {
            flex-direction: row;
        }
    }

    &__contanct {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        height: 100%;
        font-size: 16px;
        gap: 60px;

        a {
            font-size: 20px;
            margin-top: 15px;
            &:hover {
                color: var(--culti-white);
            }
        }
    }

    &__logo {
        width: 200px;
    }

    &__nav {
        display: flex;
        flex-direction: row;
        gap: 150px;
    }

    &__pages, &__explore {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: start;

        a {
            line-height: 2.5em;
            text-align: left;
            position: relative;
            width: 100%;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: var(--culti-white);
                transform-origin: bottom left;
                transition: transform 0.25s ease-out;
            }

            &:hover {
                color: var(--culti-white);

                &:after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }
        }
    }

    &__list-header {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__bottom {
        background-color: var(--culti-new-dark);
        color: var(--culti-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px 0;
        font-size: 13px;

        gap: 20px;

        @media #{$screen-992} {
            flex-direction: row;
        }
    }

    &__terms {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 40px;
        font-weight: 300;

        a {
            text-decoration: none;
            color: var(--culti-white);

            &:hover {
                color: var(--culti-white);
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    &__social-icon {
        height: 34px;
        width: 34px;
        padding: 8px;
        background-color: var(--culti-footer-green);
        border-radius: 4px;
        transition: all 0.3s;

        &:hover {
            background-color: var(--culti-white);

            & svg {
                color: var(--culti-footer-green);
            }
        }

        & svg {
            color: var(--culti-white);
            height: 18px;
        }
    }
}
