.c-landing-menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    height: 100%;

    &--right {
        @media #{$screen-992} {
            margin-left: auto;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        align-items: center;

        @media #{$screen-992} {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__item {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        box-sizing: border-box;
        transition: all 0.3s;
        margin: 0 5px;

        @media #{$screen-1270} {
            margin: 0 12px;
        }

        &:hover,
        &:active &:focus {

            &:before {
                content: " ";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 2px;
                z-index: 30;
                background-color: var(--culti-hover);
            }
        }
    }

    &__link {
        text-decoration: none;
        background-color: transparent;
        color: var(--culti-menu-font-color);
        font-weight: 400;
        transition: all 0.3s;
        border-bottom: 2px solid transparent;
        font-size: 1.25rem;
        margin: 0 auto;
        width: max-content;
        padding: 0;

        &:hover,
        &:active &:focus {
            color: var(--culti-black);
        }

        @media #{$screen-992} {
            padding: 0 6px;
            position: relative;
            display: flex;
            justify-content: center;
            line-height: 1.7;
            letter-spacing: 0;
            font-size: 16px;

        }
    }

    &__dropdown-item {
        position: relative;
        padding-right: 1em;
        transition: all 0.3s;

        @media #{$screen-992} {
            background: url(../img/icon-arrow-down.svg) no-repeat right;
            background-size: 1em;

            &:hover {
                cursor: pointer;

                & .c-landing-menu__submenu {
                    display: flex;
                }
            }
        }
    }

    &__submenu {
        transition: all 0.3s;
        position: absolute;
        top: 86px;
        left: -6px;
        display: none;
        border: none;
        list-style: none;
        flex-direction: column;
        background-color: rgba(248,249,250,1);
        align-items: center;
        border-radius: 5px;
        color: var(--culti-menu-font-color);
        box-shadow: 0px 3px 10px 0px hsla( 270 , 2% , 19% ,calc( 100% - 97%));
    }

    &__submenu-item {
        transition: all 0.3s;
        padding: 12px 20px;
        width: 100%;

        &:hover {
            background-color: var(--culti-hover);
        }

        &:last-child {
            border-radius: 0 0 5px 5px;
        }
    }

    &__submenu-link {
        color: var(--culti-menu-font-color);
        text-align: start;
        font-weight: 400;
        font-size: .8rem;
        white-space: nowrap;

        &:hover {
            color: var(--culti-black);
        }
    }

    &__button-wrapper {
        width: auto;
        display: flex;
        justify-content: center;

        @media #{$screen-992} {
            width: 20%;
        }
    }

    &__button {
        margin: 0;
        font-size: 15px;
        display: flex;
        align-items: center;
        background-color: var(--culti-dark-green);
        text-transform: none;
        white-space: nowrap;


        @media #{$screen-992} {
            margin: 0 14px;
            font-size: 15px;
            padding: 13px 29px;
        }

        @media #{$screen-1200} {
            margin: 0 28px;
            font-size: 15px;
            padding: 13px 29px;
        }

        &:hover {
            background-color: var(--culti-black);
        }
    }
}
