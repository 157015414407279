.paginator .pagination {
    a {
        color: var(--culti-black);
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        &:hover {
            color: var(--culti-green);
        }
    }
    li {
        padding: 10px;
    }
    display: flex;
    justify-content: center;
}
.paginator {
    color: var(--culti-black);
    font-size: 12px;
}
.paginator .pagination-info {
    padding: 0.825rem;
    font-weight: 600;
    text-align: right;
}

.table-buttons {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0.5rem 0 0.5rem 0;
}

.search-button {
    padding: 20px;
}

.table-shadow {
    box-shadow: 6.5px 4.5px 3.2px 0 rgba(47, 96, 36, 0.1);
    tbody {
        tr {
            td {
                /* word-break: break-all; */
            }
        }
    }
}

.vertical-table {
    tbody {
        tr {
            th {
                background-color: var(--culti-dark-green);
                color: white;
                width: 30%;
            }
        }
    }
}

.vertical-table-red {
    tbody {
        tr {
            th {
                background-color: var(--culti-red);
                color: white;
                font-size: 18px;
                padding: 0.5rem;
            }
        }
        td {
            text-align: left;
            padding: 30px 40px;
        }
    }
}

