/* Styles for content part of portal */

/* General styles */

.culti-content {
    background-color: #fff;
}

.culti-content .button {
    //margin: 0 auto;
}


/* Forms in content part */

.culti-content .culti-form {
    width: 50%;
    margin: 2em auto;
}

/* Multi-column content */
#layoutSidenav_content main.culti-two-column {
    display: flex;
    height: 100%;
}

#layoutSidenav_content .culti-column {
    flex: 50%;
    height: 100%;
}

#layoutSidenav_content .culti-two-column .culti-column .culti-form,
#layoutSidenav_content .culti-two-column .culti-column .culti-content-header
{
    width: 55%;
}

/* No sidenav */

#layoutNoSidenav {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--culti-white);
    width: 100%;
    padding-top: 2em;
    top: 70px;
}




