.c-landing-body {
    &.prevent-scroll {
        margin: 0;
        height: 100%;
        overflow: hidden;

        @media #{$screen-992} {
            height: unset;
            overflow: visible;
        }
    }
}
