.c-divider {
    background-color: var(--culti-dark-green);

    &__inner {
        padding: 2rem;
        text-align: center;
        max-width: 75rem;
        margin: 0 auto;

        @media #{$screen-768} {
            padding: 3rem;
        }

        @media #{$screen-992} {
            padding: 4rem;
        }
    }

    &__white-text {
        font-size: 1.125rem;
        font-weight: bold;
        color: white;
        margin-bottom: 1.5rem;

        @media #{$screen-420} {
            font-size: 1.25rem;
        }

        @media #{$screen-992} {
            font-size: 1.325rem;
        }
    }

    &__green-text {
        font-size: 1rem;
        font-weight: bold;
        font-style: italic;
        text-align: center;
        color: var(--culti-green);
        margin-bottom: 0rem;

        @media #{$screen-992} {
            font-size: 1.125rem;
        }

        @media #{$screen-992} {
            font-size: 1.25rem;
        }
    }

    &__input-wrapper {
        display: flex;
        flex-direction: row;
        max-width: 42rem;
        margin: 2rem auto 0 auto;
        border: 4px solid white;
        border-radius: 6px;
        background-color: white;
    }

    &__input {
        background-color: white;
        font-size: 1rem;
        color: #989a9f;
        height: initial;
        padding: 0.5rem;
        margin: 0;
        border: none;
       
        &:focus {
            outline: none;
        }

        @media #{$screen-768} {
            padding: 1rem;
        }
    }

    &__button {
        background-color: var(--culti-black);
        font-size: 1rem;
        font-weight: bold;
        color: white;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        border: 1px solid var(--culti-black);
        border-radius: 6px;
        outline: none;

        &:after, &:focus {
            border: none;
            outline: none;
            background-color: var(--culti-green);
        }

        @media #{$screen-768} {
            font-size: 1.365rem;
            padding: 0.5rem 2rem;
        }
    }
}
