.c-contact {
    &__heading {
        text-align: center;

        @media #{$screen-992} {
            text-align: left;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 0rem;

        @media #{$screen-992} {
            margin: 0rem 0 0 -4rem;
            flex-direction: row;
        }

        @media #{$screen-1200} {
            margin: 0 0 0 -8rem;
        }
    }

    &__info-wrapper {
        width: 100%;
        padding: 0 2rem;

        @media #{$screen-992} {
            width: calc(55% - 4rem);
            margin-left: 4rem;
            padding: 3rem 0 8rem 8rem;
        }

        @media #{$screen-1200} {
            width: calc(55% - 8rem);
            margin-left: 8rem;
            padding: 3rem 0 8rem 12rem;

        }
    }

    &__info {
        position: relative;

        &::after {
            display: none;
            position: absolute;
            content: '';
            background-image: url(../img/bg-poly-contact-1.svg);
            background-repeat: no-repeat;
            width: 20rem;
            height: 15rem;
            bottom: 0%;
            left: 33%;
            transform: translate(-50%, 60%);
        }

        @media #{$screen-992} {
            &::after {
                display: block;
            }
        }

    }

    &__title-wrapper {
        margin-bottom: 1rem;
        display: flex;

        img {
            margin-right: 0.5rem;
            height: 1.25rem;
            width: auto;
        }
    }

    &__content {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.36;

        span {
            font-weight: 400;
            font-style: italic;
            margin-right: .5em;
        }

        &--space {
            margin: 2rem 0;
        }
    }

    &__form-wrapper {
        margin: 1rem;
        box-shadow: 0.5px 8px 15px 0 rgba(51, 51, 51, 0.15);
        border-radius: 10px;

        @media #{$screen-992} {
            padding: 4em 0 3em 0;
            width: calc(45% - 4rem);
            margin: 0 0 0 4rem;
        }

        @media #{$screen-1200} {
            width: calc(45% - 8rem);
            margin: 0 0 0 8rem;
        }
    }

    &__form {
        width: 60%;
        margin: auto;
    }
}
