#sidenavAccordion {
    background-color: transparent;
}

.c-sidenav-menu {
    border-right: none;
    border-top-right-radius: 0.5rem;
    height: 100%;
    overflow-x: hidden;

    hr {
        margin-top: .7rem;
        margin-bottom: .7rem;
    }

    &__blocks {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    &__block {
        width: var(--sidenav-width);
        transition: all 0.4s;
        display: block;
    }

    &__block-inner {
        padding-top: 1.25rem;
        overflow-y: auto;
        height: 100%;
    }

    &__block-top {
        position: relative;
        background-color: var(--culti-light-grey);
        padding: 1.25rem;
        text-align: left;
    }

    &__block-title {
        display: block;
        font-size: 0.875rem;
        font-weight: 600;
        font-style: normal;
        color: var(--culti-black);
        margin-bottom: 0.25rem;
    
    }

    &__block-subtitle {
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--culti-green);
        margin-bottom: 0;
    }

    &__close-button {
        position: absolute;
        border: none;
        right: 0.75rem;
        top: 0.75rem;
        border-radius: 50%;
        background-color: white;
        padding: 0.5rem;
        line-height: 0;
        outline: none;

        &:active, &:focus {
            outline: none;
        }
    }

    &__close-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__search {
        width: 80%;
    }

    &__button-wrapper {
        text-align: center;
        margin: 1rem auto;

        .c-btn-primary {
            display: inline-block;
            margin: 0;
            width: 50%;
        }
    }

    &__button {
        display: flex;
        width: 100%;
        padding: 0.3125rem 1.5rem;
    }

    &__button-logo {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        color: var(--culti-black);
        font-weight: bold;
        user-select: none;
        border-left: 2px solid transparent;
        padding: 0 1rem;
        width: 100%;

        &:hover,
        &.hover {
            cursor: pointer;
            text-decoration: none;
            color: var(--culti-black);
            background-color: var(--culti-dark-grey);
            border-left: 2px solid var(--culti-black);
        }

        &.collapsible:not(.collapsed) {
            background-color: var(--culti-dark-grey);
            border-left: 2px solid var(--culti-black);
        }

        &--generic {
            a {
                font-size: 0.8rem;
                font-weight: bold;
                color: var(--culti-black);
                user-select: none;
                width: 100%;
                padding: 0.875rem 0rem;

                &:hover {
                    color: var(--culti-black);
                }
            }
        }
    }

    &__link {
        font-size: 0.8rem;
        font-weight: bold;
        color: var(--culti-black);
        user-select: none;
        padding: 0.875rem 0rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            color: var(--culti-black);
        }

        &--sub-text {
            font-weight: normal;
        }
    }

    &__link-icon {
        width: 1.8rem;
        max-height: 1.35rem;
        display: inline-block;
        margin-right: 0.5rem;
    }

    &__edit-button {
        display: inline-block;
        width: 1em;
        margin-left: auto;
        margin-right: 1em;
        flex-shrink: 0;

        &:not(.show) {
            display: none;
        }
    }

    &__delete-button {
        display: inline-block;
        width: 1em;
        margin-left: auto;
        margin-right: 1em;
        flex-shrink: 0;

        &:not(.show) {
            display: none;
        }
    }

    &__collapse-button {
        display: inline-block;
        width: 1em;

        &:not(.show) {
            display: none;
        }
    }
}
