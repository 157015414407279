.c-contact-map {
    &__polygon {
        &::after {
            content: url(../img/bg-poly-contact-2.svg);
            transform: translate(50%, -50%);
            top: 0;
            right: 0;
        }
    }

    &__title {
        text-align: center;
        padding: 8em 0 4em 0;
    }

    &__container {
        padding: 0;
    }

    &__map {
        position: relative;
        height: 400px;
    }

    &__mapframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}
