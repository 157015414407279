@media (max-width: 1023px) {
    .no-mobile {
        display: none;
    }
}

@media (min-width: 1024px) {
    .mobile {
        display: none;
    }
}