.c-btn-primary {
    display: inline-flex;
    margin: 1em auto;
    padding: 0.4em 1.2em;
    font-weight: 600;
    text-align: center;
    color: var(--culti-white);
    background-color: var(--culti-green);
    border: none;
    border-radius: .4em;
    width: max-content;
    outline: none;
    transition: all 0.4s;
    font-size: 12px;
    text-transform: uppercase;

    @media #{$screen-992} {
        margin: 1em 0;
    }

    &:hover {
        color: var(--culti-white);
        cursor: pointer;
        background-color: var(--culti-hover);
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: var(--culti-dark-grey);
    }

    a {
        color: var(--culti-white);
        outline: none;
    }
}

.c-btn-table, .c-table .c-btn-table {
    display: inline-flex;
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: var(--culti-white);
    background-color: var(--culti-green);
    border: none;
    border-radius: .4em;
    transition: all 0.4s;
    font-size: 0.75rem;
    margin: 0.625rem;
    outline: none;
    text-transform: uppercase;

    &__small {
        margin: 1em auto;
        padding: 0.4em 1.2em;
    }

    &:hover {
        color: var(--culti-white);
        cursor: pointer;
        background-color: var(--culti-hover);
    }

    &:disabled, &__disabled, &__disabled:hover {
        color: var(--culti-darker-grey);
        font-style: normal;
        background-color: var(--culti-dark-grey);
        cursor: default;
    }
}


.c-btn-secondary {
    display: inline-flex;
    margin: 1em auto;
    padding: 0.4em 1.2em;
    font-weight: 600;
    text-align: center;
    color: var(--culti-black);
    background-color: transparent;
    border: 1px solid var(--culti-green);
    border-radius: .4em;
    width: max-content;
    outline: none;
    transition: all 0.4s;
    font-size: 12px;
    text-transform: uppercase;

    &:hover, &:focus {
        outline: none;
        color: var(--culti-black);
        border: 1px solid var(--culti-black);
    }
}


/*use this class when defining any submit buttons please*/
.c-btn-submit {
    display: block;
    padding: 0.4rem 1rem;
    font-size: 1.1em;
    margin: 1rem auto;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: var(--culti-white);
    background-color: var(--culti-green);
    border: none;
    border-radius: 0.4em;
    width: max-content;
    transition: all 0.4s;
    text-transform: uppercase;

    &:hover, &:focus {
        color: var(--culti-white);
        outline: none;
        cursor: pointer;
        background-color: var(--culti-hover);
    }

    &:disabled {
        background-color: var(--culti-dark-grey);
    }
}

.button__icon {
    height: 1.2em;
    margin-left: 0.4em;
}
