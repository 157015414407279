.c-portal-topnav {
    font-weight: 600;
    color: var(--culti-black);
    background: white;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    flex-direction: column;
    top: -10000px;
    right: 0;
    padding: 1rem 2rem 2rem 2rem;
    overflow-y: auto;
    
    &[data-state~="opened"] {
        top: 4.375rem;
        height: calc(100vh - 4.375rem);
        width: 25%;
        box-shadow: inset 0 5px 8px 0 rgba(0, 0, 0, 0.1);
    }

    @media #{$screen-1200} {
        top: 0;
        position: static;
        flex-direction: row;
        padding: 0rem;
        height: unset;
        overflow-y: visible;

        &[data-state~="opened"] {
            top: 0;
            height: unset;
        }
    }
}
