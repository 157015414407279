.c-sidenav-form {
    &.hidden {
        display: none;
    }

    &__top {
        padding: 0 1rem;
    }

    &__config {
        display: block;

        &.hidden {
            display: none;
        }
    }

    &__main {
        display: block;
    }

    &__layer-select {
        margin-bottom: 1.375rem;
        font-size: 0.75rem;
    }

    &__list {
        list-style: none;
    }

    &__item {
        margin-bottom: 1.65rem;

        &--no-margin {
            margin-bottom: 0rem;
        }

        label {
            font-size: 0.625rem;
        }

        input {
            font-size: 0.75rem;
            margin-bottom: 0rem;
        }

        select {
            font-size: 0.75rem;
            margin-bottom: 0rem;
        }
    }

    &__divider {
        margin: 1.65rem 0rem !important;
    }

    &__button {
        font-size: 0.75rem !important;
    }
}

.c-language-form {
    width: 100%;
    text-transform: uppercase;
}
