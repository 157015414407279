.c-landing-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2.2rem 0 1.2rem;
    position: fixed;
    width: 100%;
    background-color: white;
    height: 86px;
    justify-content: space-around;
    left: 0;
    top: 0;
    z-index: 10000;
    transition: all 0.3s ease-in-out;

    @media #{$screen-992} {
        padding: 0 30px;
        height: 86px;
    }

    &[data-scroll~="true"] {
        margin-bottom: 0;
        height: 86px;

        .c-landing-menu__submenu {
            top: 86px;
        }
        // box-shadow: 0.6px 4.5px 7.1px 0 rgba(51, 51, 51, 0.15);
    }

    &__wrapper {
        //max-width: 1248px;
        flex: 1;
        height: 100%;
        display: flex;
        width: 104%;
        max-width: 104%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 -2%;
    }

    &__logo-wrapper {
        margin-left: 0;
        padding-left: 20px;

        @media #{$screen-1270} {
            margin-left: 40px;
            padding-left: 100px;
        }
    }

    &__brand {
        width: 140px;
        max-width: 100%;
        padding: 0;
        z-index: 20;

        @media #{$screen-1270} {
            width: 200px;
        }

    }

    &__logo {
        width: 140px;
        max-width: 100%;

        &[data-state~="hidden"] {
            display: none;
        }

        @media #{$screen-1270} {
            width: 200px;
        }
    }
}
