.c-document {
  margin-bottom: 8em;

  &__polygon {
    &::after {
      content: url(../img/bg-poly-about.svg);
      transform: translate(50%, -50%);
      top: 0;
      right: 0;
    }
  }
  
  a {
    color: var(--culti-green);
  }
  
  a:hover {
    color: var(--culti-dark-green);
  }
}
